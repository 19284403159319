// Node Modules
import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from 'react-markdown'
import { openPopupWidget } from "react-calendly";

// Components
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import Button from "../components/Button";
import WidgetProjects from '../components/WidgetProjects'
import HeroPackages from "../components/HeroPackages";

const PackageList = ({ data, location, pageContext }) => {
  const packages = data.packagedata
  const settings = data.packagesettings.childMarkdownRemark.frontmatter
  const { currentPage, numPackPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPackPages
  const prevPage = currentPage - 1 === 1 ? "/packages/" : ("/packages/" + (currentPage - 1).toString() + "/")
  const nextPage = ("/packages/" + (currentPage + 1).toString() + "/")
  const settingpackagesfeatimageLocal = data.packagesettings.childMarkdownRemark.settingpackagesfeatimageLocal
  return (
    <>
      <Layout location={location} isOpacity={true}>
        <Seo strTitle={settings.settingpackagesseotitle} strDescription={settings.settingpackagesseometadesc} strImage={settingpackagesfeatimageLocal.publicURL} />
        <HeroPackages
          featuredImage={settingpackagesfeatimageLocal}
          featuredImageAlt={settings.settingpackagesfeatimagealt}
          settingherobtn1enable={settings.settingherobtn1enable}
          settingherobtn2enable={settings.settingherobtn2enable}
          settingpackagesherotitle={settings.settingpackagesherotitle}
          settingherobtn1text={settings.settingherobtn1text}
          buttononelink={settings.buttononelink[0].menulink}
          buttononetype={settings.buttononelink[0].type}
          buttononedesc={settings.buttononedesc}
          settingherobtn2text={settings.settingherobtn2text}
          buttontwolink={settings.buttontwolink[0].menulink}
          buttontwotype={settings.buttontwolink[0].type}
          buttontwodesc={settings.buttontwodesc}
        />
        <div className="w-full bg-white relative sm:pb-16" style={{ paddingTop: "0.2px", paddingBottom: "0.2px" }}>
          <div id="packages" className="absolute -top-16"></div>
          <section className="projects bg-white pt-4 xs:!pb-2 sm:!pb-16 ">
            <div className="container mx-auto" >
              <div className="cmscontent py-6 xs:mx-4 sm:mx-4 md:mx-10" dangerouslySetInnerHTML={{ __html: data.packagesettings.childMarkdownRemark.html }} />
            </div>
            <section className="container mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-8 xs:mx-4 sm:mx-4 md:mx-10">
                {packages.edges.filter(edge => edge.node.frontmatter.production === true).map((edge, index) => {
                  const frontmatter = edge.node.frontmatter;
                  const image = getImage(edge.node.featuredimageLocal);
                  const url = frontmatter.link;
                  const onClickPack = () => openPopupWidget({ url });
                  const slugId = edge.node.fields.slug.split('/')[2];
                  return (
                    <div className="flex flex-col overflow-hidden bg-white shadow-lg relative" key={index} >
                      <div id={slugId} className="absolute -top-16"></div>
                      <div className="grid w-full" >
                        <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                          className="max-h-80 xl:max-h-96"
                          layout="fullWidth"
                          aspectratio={3 / 1}
                          style={{ gridArea: "1 / 1" }}
                        />
                      </div>
                      <div className="w-full h-full p-4 md:p-4 flex flex-col bg-white">
                        <h3 className="text-lg md:text-xl text-gray-800">{frontmatter.title}</h3>
                        <div className="mt-2 text-gray-600 cmscontent cmscards text-sm md:text-lg">
                          <ReactMarkdown linkTarget="_blank">
                            {frontmatter.description}
                          </ReactMarkdown>
                        </div>
                        <div className="flex justify-between mt-3 h-full items-end">
                          <div className="text-sm md:text-base font-semibold text-gray-700">{frontmatter.price > 0 ? "£" + frontmatter.price : "FREE"}</div>
                          <div>
                            <Button radius={"0px"} btnStyle={"dark"} onClick={onClickPack} fontWeight={400}>{settings.settingpackageslnkbtn}</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </section>
            {numPackPages > 1 && (
              <div className="container mx-auto">
                <div className="flex flex-row justify-between pt-3 pb-2">
                  <div className="text-left">
                    {!isFirst && (
                      <Link to={prevPage} rel="prev">
                        <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>Previous Page</Button>
                      </Link>
                    )}
                  </div>
                  <div className="text-left">
                    {!isLast && (
                      <Link to={nextPage} rel="prev">
                        <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>Next Page</Button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
          </section>
          <WidgetProjects />
        </div>
      </Layout>
    </>
  );
};

export default PackageList;
export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    packagesettings: file(relativePath: { eq: "settings/packages.md" }) {
      id
      childMarkdownRemark {
        html
        settingpackagesfeatimageLocal {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
          ext
          publicURL
        }
        frontmatter {
          settingpackagestitle
          settingpackageslnkbtn
          settingpackagesfeatimagealt
          settingpackagesseotitle
          settingpackagesseometadesc
          settingpackagesherotitle
          settingherobtn1text
          settingherobtn1enable
          buttononelink {
            menulink
            type
          }
          buttononedesc
          settingherobtn2text
          settingherobtn2enable
          buttontwolink {
            menulink
            type
          }
          buttontwodesc
        }
      }
    }
    packagedata: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(packages)/.*[.]md$/" } }
      limit: $limit
      skip: $skip
      sort: {fields: frontmatter___disporder, order: ASC}
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          featuredimageLocal {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
            ext
            publicURL
          }
          excerpt(pruneLength: 250)
          frontmatter {
            production
            datepub(formatString: "DD MMMM, YYYY")
            title
            disporder
            description
            price
            link
            featuredimagealt
          }
        }
      }
    }
  }
`;
