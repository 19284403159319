import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components

// Assets
import square from '../images/square.svg'

const WidgetProjects = (props) => {
  const { gallerysettings, projects } = useStaticQuery(
    graphql`
          query {
              gallerysettings: file(relativePath: {eq: "settings/projects.md"}) {
                  id
                  childMarkdownRemark {
                    settingprojfeatimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          transformOptions: { fit: COVER, cropFocus: CENTER }
                        )
                      }
                    }
                      frontmatter {
                        settingprojtitle
                        settingprojlnkbtn
                        settingprojfeatimagealt
                      }
                  }
              }
              projects: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(projects)/.*[.]md$/"}, frontmatter: {production: {eq: true}}}
                limit: 4
                sort: {fields: frontmatter___datepub, order: DESC}
              ) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    id
                    featuredimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          layout: FULL_WIDTH
                        )
                      }
                    }
                    frontmatter {
                      production
                      title
                      datepub
                      featuredimagealt
                    }
                  }
                }
              }
          }
        `
  );
  const settings = gallerysettings.childMarkdownRemark.frontmatter
  const allprojimage = getImage(gallerysettings.childMarkdownRemark.settingprojfeatimageLocal)
  return (
    <>
      <section className="bg-template-black100 h-20 mb-2 flex flex-wrap content-center justify-end">
        <div className="container mx-auto flex flex-row items-center">
          <img src={square} alt="Nine Lives Design Circle" className="w-11 h-11 xs:ml-4 sm:ml-4 md:ml-10 mr-2" />
          <h2 className="text-lg md:text-xl leading-7 font-light text-white mx-2">{props.title ? props.title : settings.settingprojtitle}</h2>
        </div>
      </section>
      <section className="projects container mx-auto mb-2">
        <div className="gallery xs:mx-4 sm:mx-4 md:mx-10">
          {projects.edges.filter((edge, idx) => edge.node.frontmatter.title !== props.currentProject).filter((edge, idx) => idx < 3).map(edge => {
            const frontmatter = edge.node.frontmatter
            const image = getImage(edge.node.featuredimageLocal)
            return (
              <div className="imgContainer imgContainerHover" key={edge.node.id} >
                <Link to={`/projects${edge.node.fields.slug}/`}>
                  <div className="grid h-full" >
                    <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                      className="row-start-1 col-start-1 imgWrapper"
                      layout="fullWidth"
                      aspectratio={3 / 1}
                      style={{ gridArea: "1 / 1" }}
                    />
                    <div className="title">
                      <h2>{frontmatter.title}</h2>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
          <div className="imgContainer" >
            <Link to="/projects/">
              <div className="grid h-full" >
                <GatsbyImage image={allprojimage} alt={settings.settingprojfeatimagealt}
                  className="row-start-1 col-start-1 imgWrapper"
                  layout="fullWidth"
                  aspectratio={3 / 1}
                  style={{ gridArea: "1 / 1" }}
                />
                <div className="title">
                  <h3 className="">{settings.settingprojlnkbtn}</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default WidgetProjects