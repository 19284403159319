// Node Modules
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby';

// Styles

// Components

// Assets
import Button from './Button';
import IconDownArrow from '../images/IconDownArrow';

const HeroPackages = ({
    featuredImage,
    featuredImageAlt,
    settingherobtn1enable = false,
    settingherobtn2enable = false,
    settingpackagesherotitle,
    settingherobtn1text,
    buttononetype,
    buttononelink,
    buttononedesc,
    settingherobtn2text,
    buttontwotype,
    buttontwolink,
    buttontwodesc,
}) => {

    return (
        <>
            <section className="h-screen">
                <div className="fixed top-0 w-full h-4/6" style={{ display: "grid" }} >
                    <GatsbyImage image={featuredImage.childImageSharp.gatsbyImageData}
                        alt={featuredImageAlt}
                        className="row-start-1 col-start-1"
                        layout="fullWidth"
                        aspectratio={3 / 1}
                        style={{ gridArea: "1 / 1" }}
                    />
                </div>

                <div className="fixed bottom-0 w-full bg-white pt-1">
                    <div className="w-full flex justify-center mb-1">
                        <div className="py-1 px-9 w-max ">
                            <h1 className="text-center text-2xl md:text-4xl lg:text-4xl font-light text-template-greenDark leading-tight uppercase">
                                {settingpackagesherotitle}
                            </h1>
                        </div>
                    </div>
                    <div className="left-0 w-full flex flex-col justify-center z-10 sm:flex-row">
                        {settingherobtn1enable &&
                            <div className="flex flex-col items-center">
                                <div className="px-3">
                                    {buttononetype === "sectionlink" ?
                                        <AnchorLink href={buttononelink}>
                                            <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>{settingherobtn1text}</Button>
                                        </AnchorLink>
                                        :
                                        <Link to={buttononelink}>
                                            <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>{settingherobtn1text}</Button>
                                        </Link>
                                    }
                                </div>
                                <div className="flex flex-row px-3 cmscontent">
                                    <p className="max-w-xs px-6 text-justify">{buttononedesc}</p>
                                </div>
                            </div>
                        }
                        {settingherobtn2enable &&
                            <div className="flex flex-col items-center">
                                <div className="px-3">
                                    {buttontwotype === "sectionlink" ?
                                        <AnchorLink href={buttontwolink}>
                                            <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>{settingherobtn2text}</Button>
                                        </AnchorLink>
                                        :
                                        <Link to={buttontwolink}>
                                            <Button radius={"0px"} btnStyle={"dark"} fontWeight={400}>{settingherobtn2text}</Button>
                                        </Link>
                                    }

                                </div>
                                <div className="flex flex-row px-3 cmscontent">
                                    <p className="max-w-xs px-6 text-justify">{buttontwodesc}</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="left-0 w-full flex justify-center z-10 mt-2">
                        <AnchorLink href="#packages" aria-label="Scroll Down To See More">
                            <IconDownArrow width={30} className="text-template-greenDark animate-bounce" />
                        </AnchorLink>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroPackages;
